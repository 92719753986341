var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 基本情况 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"报告编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['number', 
                { rules: [{ required: true, message: '请输入' }] },]),expression:"['number', \n                { rules: [{ required: true, message: '请输入' }] },]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"报告单位"}},[_c('CompanySelector',{on:{"change":(company) => (_vm.selectedCompany = company)}},[_c('a-button',{staticClass:"ellipsis",staticStyle:{"margin-top":"4px","text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.selectedCompany.name)+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"报告时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'reportTime',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'reportTime',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"接收人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['receiverName', 
                { rules: [{ required: true, message: '请输入' }] },]),expression:"['receiverName', \n                { rules: [{ required: true, message: '请输入' }] },]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 报告人 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reporterName', 
                { rules: [{ required: true, message: '请输入' }] },]),expression:"['reporterName', \n                { rules: [{ required: true, message: '请输入' }] },]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reporterMobile', 
                ]),expression:"['reporterMobile', \n                ]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 现场负责人 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['siteManagerName', 
                ]),expression:"['siteManagerName', \n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['siteManagerMobile', 
                ]),expression:"['siteManagerMobile', \n                ]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 事故信息 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故类别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accCategory',
              ]),expression:"[\n                'accCategory',\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.acc_category),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故级别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accLevel',
              ]),expression:"[\n                'accLevel',\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.acc_level),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['accName', 
                ]),expression:"['accName', \n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故发生地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['accLocation', 
                
                ]),expression:"['accLocation', \n                \n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故发生时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accTime',
           
              ]),expression:"[\n                'accTime',\n           \n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故发生单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['accOrg', 
              ]),expression:"['accOrg', \n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"事故经过简要描述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accDesc',
              ]),expression:"[\n                'accDesc',\n              ]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 人员伤亡情况 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"死亡人数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['deadNum', 
           ]),expression:"['deadNum', \n           ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"失踪人数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['missingNum', 
              ]),expression:"['missingNum', \n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"重伤人数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['injuredNum', 
              ]),expression:"['injuredNum', \n              ]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 经济损失情况 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"直接经济损失(万)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['directEconomicLoss', 
              ]),expression:"['directEconomicLoss', \n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"其他经济损失情况","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'otherEconomicLoss',
              
              ]),expression:"[\n                'otherEconomicLoss',\n              \n              ]"}]})],1)],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 其他 ")]),_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"目前已经采取的控制措施","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'controlMeasures',
             
              ]),expression:"[\n                'controlMeasures',\n             \n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',
             
              ]),expression:"[\n                'remark',\n             \n              ]"}]})],1)],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("确认修改")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }